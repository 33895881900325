<template>
    <div>
        <Navbar/>
         <b-container class="mt-5">
            <b-row>
                <b-col cols="12" sm="12" md="12" lg="12">
                    <h3 class="txt_secondary text-uppercase mb-3  pl-3">Mis pacientes</h3>
                    <b-container> 
                        <b-skeleton-wrapper class="p-0 m-0" :loading="loading">
                            <template #loading>
                                <b-row>
                                    <b-col v-for="item of skeleton" :key="item.id" class="mb-3" cols="12" sm="12" md="4" lg="4">
                                        <b-skeleton class="size btn-block border-0" type="input"></b-skeleton>
                                    </b-col>
                                </b-row>
                            </template>

                            <b-row>
                                <b-col v-if="empty" class="d-flex justify-content-center items-center" cols="12" sm="12" md="12" lg="12">
                                    <div class="mx-auto my-auto">
                                        <h2 class="txt_red mb-5">¡No hay pacientes!</h2>
                                        <div class="mx-auto" style="width: auto; margin: auto auto auto auto;">
                                            <b-img class="mx-auto" :src="require('../../../assets/img/empty_paciente.png')" style="max-height: 320px; max-width: 320px;"/>
                                        </div>
                                    </div>
                                </b-col>
                                <b-col v-for="item of allPacientes" :key="item.nombre" class="mb-3" cols="12" sm="12" md="4" lg="4">
                                    <div class="input-group flex-nowrap cursor" v-bind:title="item.nombre" @click="getExpediente(item.id)">
                                        <span class="input-group-text" id="img" align="center">
                                            <i class="fa fa-user fa-2x"></i>
                                        </span>
                                        <p type="text" class="form-control contenedor bg_third" id="contenido">{{ item.nombre }}<br>Habitación: {{item.lastExpediente.no_habitacion}}</p>
                                    </div>
                                </b-col>
                            </b-row>
                            <div class="overflow-auto mt-4" v-if="pacientes.length>15">
                                <div class="mt-3">
                                    <b-pagination v-model="currentPage" :total-rows="rows" align="center"></b-pagination>
                                </div>
                            </div>
                        </b-skeleton-wrapper>
                    </b-container>
                </b-col>
            </b-row>
         </b-container>
    </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue"
import {url} from "../../../../settings"
import router from '../../../router';
import CryptoJS from 'crypto-js'
export default {
    name: 'MisPacientes',
    components: { Navbar },

    data:() =>({
        currentPage: 1,
        perPage:15,

        empty: false,
        loading: false,
        skeleton: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18],
        pacientes: []
    }),

    computed: {
        allPacientes() {
            const items = this.pacientes;
            // Return just page of items needed
            return items.slice(
                (this.currentPage - 1) * this.perPage,
                this.currentPage * this.perPage
            )
        },

        rows() {
            return this.pacientes.length
        },
    },

    methods: {
        async getPacientes() {
            const idDoc=localStorage.idEncrypt
            const id =  CryptoJS.AES.decrypt(idDoc, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            this.loading = true;
            try {
                const res = await fetch(`${url}/api/pacientes/perMedico/${id}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': "Bearer "+ localStorage.getItem("token"),
                    }
                });
                if (!res.ok) {
                    const message = `An error has occured: ${res.status} - ${res.statusText}`;
                    throw new Error(message);
                }

                const data = await res.json();
                this.pacientes = data.pacientes

                if (this.pacientes.length === 0) {
                    this.empty = true;
                    this.loading = false;
                } else {
                    this.empty = false;
                    this.loading = false;
                }
                
            } catch (error) {
                console.log(error);
            }
        },
        async getExpediente(value) {
            try {
                router.push("/notas-medicas/"+value)
            } catch (error) {
                console.log("el error es"+error);
            }
            
        },
    },

    mounted() {
        this.getPacientes();
    },
    destroyed() {  
        this.$destroy();
    }
}
</script>

<style scoped>
    .size_th_skele {
        width: 100vh;
    }

    .size {
        height: 60px;
         background: linear-gradient(-45deg, #dddddd, #f0f0f0, #dddddd, #f0f0f0);
        background-size: 400% 400%;
        -webkit-animation: skeletonLoading 2.25s ease infinite;
        -moz-animation: skeletonLoading 2.25s ease infinite;
        animation: skeletonLoading 2.25s ease infinite;
    }

    .animacion {
        height: 30px;
        background: linear-gradient(-45deg, #dddddd, #f0f0f0, #dddddd, #f0f0f0);
        background-size: 400% 400%;
        -webkit-animation: skeletonLoading 2.25s ease infinite;
        -moz-animation: skeletonLoading 2.25s ease infinite;
        animation: skeletonLoading 2.25s ease infinite;
    }

     @-webkit-keyframes skeletonLoading {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    @-moz-keyframes skeletonLoading {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    @keyframes skeletonLoading {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }

    .contenedor {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .cursor {
        cursor: pointer;
    }

    #contenido {
        border-radius: 15px;
        height: auto;
        margin-left: 35px;
        justify-content: center;
        text-align: center;
    }

    #img {
        position: absolute;
        border-radius: 50px;
        width: auto;
        height: 3.1rem;
        margin-top: 4px;
        z-index: 1;
        background: white;
        justify-content: center;
        align-items: center;
    }

    .btn_border {
        color: #689aaf;
        background-color: #fff;
        border: 1px solid #689aaf;
    }

    .push_btn_blue {
        color: white;
        background-color: #689aaf;
    }

    p {
        font-size: 0.8rem;
    }

    i {
        color: #ced4da;
    }
</style>