<template>
    <div>
        <b-navbar class="bg_primary py-2" type="dark" toggleable="lg">
            <b-navbar-brand href="#">
                <b-img class="svg" :src="require('../assets/Healtech_heart.svg')" alt=""/>
                <router-link to="/main" tag="span" custom v-slot="{navigate}">
                    <span class="pl-2" @click="navigate" @keypress.enter="navigate"><span class="txt_white  title">HEALTECH</span></span>
                </router-link>
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-auto">
                    <b-navbar-nav>
                        <b-nav-item>
                            <router-link to="/main" tag="span" custom v-slot="{navigate}">
                                <span class="option" @click="navigate" @keypress.enter="navigate"><span class="txt_white">INICIO</span></span>
                            </router-link>
                        </b-nav-item>
                        <b-nav-item>
                            <router-link to="/registro" tag="span" custom v-slot="{navigate}">
                                <span class="option" @click="navigate" @keypress.enter="navigate"><span class="txt_white">REGISTRO</span></span>
                            </router-link>
                        </b-nav-item>
                        <b-nav-item>
                            <router-link to="/control/enfermeria/pacientes" tag="span" custom v-slot="{navigate}" v-if="id==1">
                                <span class="option" @click="navigate" @keypress.enter="navigate"><span class="txt_white">ENFERMERIA</span></span>
                            </router-link>
                        </b-nav-item>
                        <b-nav-item>
                            <router-link to="/administrador" tag="span" custom v-slot="{navigate}" v-if="id==1">
                                <span class="option" @click="navigate" @keypress.enter="navigate"><span class="txt_white">USUARIOS</span></span>
                            </router-link>
                        </b-nav-item>
                        <b-nav-item>
                            <router-link to="/reg_especialidades" tag="span" custom v-slot="{navigate}" v-if="id==1">
                                <span class="option" @click="navigate" @keypress.enter="navigate"><span class="txt_white">ESPECIALIDADES</span></span>
                            </router-link>
                        </b-nav-item>
                        <b-nav-item>
                            <router-link to="/catalogos" tag="span" custom v-slot="{navigate}" v-if="id==1">
                                <span class="option" @click="navigate" @keypress.enter="navigate"><span class="txt_white">CATÁLOGOS</span></span>
                            </router-link>
                        </b-nav-item>
                    </b-navbar-nav>
                    <b-nav-form v-on:submit.prevent>
                        <div class="d-flex content pl-2">  
                            <vue-typeahead-bootstrap
                                class="px-1 input_navbar"
                                v-model="query"
                                :data="pacientes"
                                :serializer="item => /[0-9]/.test(query) ? item.no_expediente_legacy ?  item.nombre + ' '+item.no_expediente+ ' expediente físico: ' + item.no_expediente_legacy : item.nombre + ' '+item.no_expediente : item.nombre"
                                highlightClass="special-highlight-class"
                                @hit="onNombreSeleccionado"
                                :minMatchingChars="3"
                                placeholder="Buscar pacientes"
                                inputClass="special-input-class"
                                :disabledValues="(selecteduser ? [selecteduser.nombre] : [])"
                                @input="lookupUser"
                                />
                                <a class="float-right py-2 px-1 mr-3 ">
                                    <i class="fa fa-search txt_white"></i>
                                </a>
                        </div>
                    </b-nav-form>

                    <b-nav-item-dropdown right>
                        <template #button-content>
                            <i class="fa fa-bars fa-lg txt_white"></i>
                        </template>
                        <b-dropdown-item class="dropdown-color" @click="perfil()">
                            <span class="fa fa-user"></span>
                            <span class="pl-2">Perfil</span>
                        </b-dropdown-item>
                        <b-dropdown-item v-if="this.id === '3'" class="dropdown-color" @click="misPacientes()">
                            <span class="fa fa-user-plus"></span>
                            <span class="pl-1">Mis pacientes</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="logout()">
                            <span class="fa fa-arrow-left"></span>
                            <span class="pl-2">Salir</span>
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
        <Logout :abrir="openLogout" @cerrarModalLogout="cerrarModalLogout()"/>
        <SesionExpirada :abrir="openSesExp" @cerrarModalSesExp="cerrarModalSesExp()"/>
    </div>
</template>
<script>
import Logout from '@/components/Modals/Logout.vue'
import router from '../router'
import { mapState } from "vuex"
import { url } from "../../settings"
import {debounce} from 'lodash';
import CryptoJS from 'crypto-js'
import SesionExpirada from './Modals/SesionExpirada.vue';
import { VerifyLog } from '../store/verifyLogget';

export default {
    name: 'Navbar',
    components: { Logout, SesionExpirada },

    data:() => ({
        idPacienteSeleccionado:"",
        openLogout: false,
        openSesExp:false,
        id:"",
        query: '',
        data: {
            word: '',
        },
        selecteduser: null,
        pacientes: [],
    }),

    computed: {
        ...mapState(['usuarioLog'])
    },

    methods: {
        lookupUser: debounce(async function(){
            try {
                this.data.word = this.query
                if (this.data.word.length>1) {
                    const res = await fetch(`${url}/api/pacientes/search`, {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Requested-With': 'XMLHttpRequest',
                            'Authorization': "Bearer "+ localStorage.getItem("token"),
                        },
                        body: JSON.stringify(this.data)
                    });
                    const data = await res.json();
                    if (data.pacientes) {
                        this.pacientes = data.pacientes
                    }else{
                        this.pacientes =[] 
                    }
                }
            } catch (error) {
                this.pacientes=[]
            }
        }, 1000), 

        async onNombreSeleccionado(nombre) {
            try {
                localStorage.removeItem('expedienteEncrypt')
                if (window.location.pathname != "/notas-medicas/"+nombre.id) {
                    router.push("/notas-medicas/"+nombre.id);
                }
                this.query=""
            } catch (error) {
                console.log("");
            }
		},

        logout() {
            this.openLogout = true;
        },

        misPacientes() {
            router.push("/mis_pacientes").catch(()=>{});
        },

        perfil() {
            router.push("/perfil").catch(()=>{});
        },
        verificarL(){
            new Promise((resolve)=>{
                resolve(VerifyLog())
            }).then((data)=>{
                if (!data) {
                    this.openSesExp=true;
                    localStorage.removeItem("token");
                    localStorage.removeItem("email");
                    localStorage.removeItem("emailEncrypt");
                    localStorage.removeItem("especialidadEncrypt");
                    localStorage.removeItem("expediente_id");
                    localStorage.removeItem("expires_at");
                    localStorage.removeItem("idEncrypt");
                    localStorage.removeItem("view");
                    localStorage.removeItem("perfilNombreEncrypt");
                    setTimeout(() => {
                        if (this.openSesExp) {
                            location.reload()
                        }
                    }, 10000);
                }
            })
        },
        cerrarModalLogout(){
            this.openLogout = false;
        },
        cerrarModalSesExp(){
            this.openSesExp = false;
            // router.push("/")
            location.reload()
        }
    },
    
    mounted() {
        this.verificarL()
        setTimeout(() => {
            this.verificarL()
        }, 30000);
        localStorage.setItem('view', "");
        
        // desencriptar
        var ide = localStorage.getItem("perfil");
        this.id =  CryptoJS.AES.decrypt(ide, 'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
    },
    destroyed() {  
        this.$destroy();
    }
}

</script>

<style>
    .special-input-class{
        background-color: transparent !important;
        border: none !important;
        width: 400px !important;
        color: white !important;
        outline:none !important;
        outline-width: 0 !important;
        box-shadow: none !important;
        -moz-box-shadow: none !important;
        -webkit-box-shadow: none !important;
    }
    .special-input-class::placeholder {
        color: white !important;
    }

    .special-highlight-class{
        font-weight: 900;
        color: #585656 !important;
    }

    .svg {
        height: 40px;
        width: 40px;
        transform: scale(1.4);
    }

    .content {
        border: solid 1px white;
        border-radius: 1rem;
        margin-right: 10px;
    }
    
    .input_navbar {
        border: none;
        color:white;
        outline: none !important;
        box-shadow: none !important;
    }

    .input_navbar::placeholder {
        color:white;
    }

    @media only screen and (min-width: 1200px) {
        .input_navbar {
            width: 25vw;
        }
    }

    @media only screen and (max-width: 1200px) {
        .input_navbar {
            width: 28vw;
        }
    }

    @media only screen and (max-width: 992px) {
        .input_navbar {
            width: 50vw;
        }
    }

    @media only screen and (max-width: 768px) {
        .input_navbar {
            width: 50vw;
        }
    }

    @media only screen and (max-width: 576px) {
        .input_navbar {
            width: 70vw;
        }
    }

    .form-rounded {
        border: none;
        padding-left: 0;
        margin-left: 0;
    }

    a {
        text-decoration: none;
        color: white;
    }

    .title {
        font-weight: 500;   
    }

    .color  {
        color: white !important;
    }

    .option {
        font-weight: 400;
    }

    .option:hover {
        border-bottom: 2px solid #fff;
    }

</style>